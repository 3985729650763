import { useState, useEffect, useRef } from 'react';
import { Row, Col, Modal, Spinner} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

export function DeviceEventModal(props) {
    const device = props.device;
    const websocket = props.websocket;
    const backendAPI = props.backendAPI;
    const [anpr_image, setANPRImage] = useState('');
    const [overview_image, setOverviewImage] = useState('');
    const [anpr_image_loading, setANPRImageLoading] = useState(true);
    const [overview_image_loading, setOverviewImageLoading] = useState(true);
    const liveDeviceEventUpdate = props.liveDeviceEventUpdate;
    const [showModal, setShowModal] = useState(false);
    const showModalRef = useRef(showModal);
    const [device_event, setDeviceEvent] = useState(props.device_event);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        showModalRef.current = showModal;
    }, [showModal]);

    async function updateANPRImage(media_id) {
        if(media_id == 'None') {
            setANPRImage(null);
            setANPRImageLoading(false);
            return;
        }
        setANPRImageLoading(true);
        if (media_id) {
            const response = await backendAPI.getMedia(media_id);
            if (response.data && response.data.media[0].image) {
                try {
                    setANPRImage(response.data.media[0].image);
                } catch (error) {
                    console.error('Error getting ANPR image:', error);
                    setANPRImage(null);
                }
            }
        }
        setANPRImageLoading(false);
    }

    async function updateOverviewImage(media_id) {
        if(media_id == 'None') {
            setOverviewImage(null);
            setOverviewImageLoading(false);
            return;
        }
        setOverviewImageLoading(true);
        if (media_id) {
            const response = await backendAPI.getMedia(media_id);
            if (response.data && response.data.media[0].image) {
                try {
                    setOverviewImage(response.data.media[0].image);
                } catch (error) {
                    console.error('Error getting overview image:', error);
                    setOverviewImage(null);
                }
            }
        }
        setOverviewImageLoading(false);
    }

    async function openModal() {
        setShowModal(true);
        if (props.device_event && !liveDeviceEventUpdate) {
            setDeviceEvent(props.device_event);
            console.log(props.device_event);
            updateANPRImage(props.device_event.anpr_image_media_id);
            updateOverviewImage(props.device_event.overview_image_media_id);
        } else {
            setLoading(true);
            try {
                const response = await backendAPI.getDeviceEventsByDeviceId(device.id, 0, 1);
                if (response.data && 'device_events' in response.data && response.data.device_events.length > 0) {
                    setDeviceEvent(response.data.device_events[0]);
                    updateANPRImage(response.data.device_events[0].anpr_image_media_id);
                    updateOverviewImage(response.data.device_events[0].overview_image_media_id);
                }
            } catch (error) {
                console.error('Error getting last device_event:', error);
            } finally {
                setLoading(false);
            }
        }

        if (liveDeviceEventUpdate) {
            websocket.registerDeviceEventCallback(handleWebsocketDeviceEventEvent);
        }
    }

    function closeModal() {
        if (liveDeviceEventUpdate) {
            websocket.deregisterDeviceEventCallback(handleWebsocketDeviceEventEvent);
        }
        setShowModal(false);
    }

    async function handleWebsocketDeviceEventEvent(data) {
        if (showModalRef.current) {
            if (device && data.device_id === device.id) {
                setDeviceEvent(data);
                if (data.anpr_image_media_id) {
                    updateANPRImage(data.anpr_image_media_id);
                }
                if (data.overview_image_media_id) {
                    updateOverviewImage(data.overview_image_media_id);
                }
            }
        }
    }

    return (
        <div style={{ float: "left" }}>
            <FontAwesomeIcon icon={faEye} style={{ cursor: "pointer", marginRight: '20px' }} onClick={() => openModal()} />
            <Modal show={showModal} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    {liveDeviceEventUpdate ? (
                        <Modal.Title>Live Device Events</Modal.Title>
                    ) : (
                        <Modal.Title>Device Event Info</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : device_event != null ? (
                        <Row className="border-light-grey">
                            <Row>
                                <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    {overview_image_loading ? (
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : overview_image ? (
                                        <img src={`data:image/jpeg;base64,${overview_image}`} style={{ width: '100%' }} alt="Not available" />
                                    ) : (
                                        <p>Overview image not available</p>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    {anpr_image_loading ? (
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : anpr_image ? (
                                        <img src={`data:image/jpeg;base64,${anpr_image}`} style={{ width: '100%' }} alt="Not available" />
                                    ) : (
                                        <p>ANPR image not available</p>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-1 mb-1" xs={12} lg={12}>
                                    <label className="text-values-dark-grey">Time: {device_event.event_time}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-1 mb-1" xs={6} lg={6}>
                                    <label className="text-values-dark-grey">VLN Text: {device_event.vln ? device_event.vln : '-'}</label>
                                </Col>
                                <Col className="mt-1 mb-1" xs={6} lg={6}>
                                    <label className="text-values-dark-grey">VLN Score: {device_event.vln_confidence}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-1 mb-1" xs={6} lg={6}>
                                    <label className="text-values-dark-grey">Vehicle Class: {device_event.vehicle_class}</label>
                                </Col>
                                <Col className="mt-1 mb-1" xs={6} lg={6}>
                                    <label className="text-values-dark-grey">Class Score: {device_event.vehicle_class_confidence}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-1 mb-1" xs={12} lg={12}>
                                    <label className="text-values-dark-grey">Direction: {device_event.direction}</label>
                                </Col>
                            </Row>
                        </Row>
                    ) : (
                        <Row>
                            <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <label className="text-values-dark-grey">No recorded Events</label>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

