// TransitTable.js
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spinner } from "react-bootstrap";
import { TransitModal } from './TransitModal.js';
import { TransitFilterModal } from './TransitFilterModal.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChartBar } from '@fortawesome/free-solid-svg-icons';

export function TransitTable({ backendAPI, websocket, zones }) {

    const [transits, setTransits] = useState([]);
    const [totalTransits, setTotalTransits] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const transitsRef = useRef(transits);
    const totalTransitsRef = useRef(totalTransits);
    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState({});
    const [filterModalShow, setFilterModalShow] = useState(false);

    useEffect(() => {
    }, [zones]);

    // useEffect(() => {
    //     websocket.registerDeviceEventCallback(handleWebsocketDeviceEventEvent);
    //     return () => websocket.deregisterDeviceEventCallback(handleWebsocketDeviceEventEvent);
    // }, [websocket]);

    useEffect(() => {
        async function fetchData(page, size) {
            setLoading(true);
            try {
                const response = await backendAPI.getTransits( //getDeviceEventsWithFilters(
                    filters.zone_id,
                    filters.status,
                    filters.startDate,
                    filters.endDate,
                    (currentPage - 1) * pageSize,
                    pageSize
                );
                setTransits(response.data.zone_transits);
                setTotalTransits(response.data.total);
            } catch (error) {
                console.error('Error getting transits:', error);
            } finally {
                setLoading(false); 
            }
        };

        fetchData(currentPage, pageSize);
    }, [backendAPI, filters, currentPage]);

    useEffect(() => {
        transitsRef.current = transits;
    }, [transits]);

    useEffect(() => {
        totalTransitsRef.current = totalTransits;
    }, [totalTransits]);

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    function handleWebsocketDeviceEventEvent(data) {
        let newTransits = [data, ...transitsRef.current];
        newTransits = newTransits.slice(0, pageSize);
        setTotalTransits(totalTransitsRef.current + 1);
        setTransits(newTransits);
	}

    const applyFilters = (filterParams) => {
        setFilters(filterParams);
    };

    return (
        <div >
            <div style={{ textAlign: "right" }}>
                <FontAwesomeIcon
                    icon={faFilter}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setFilterModalShow(true)}
                />
                {/* <FontAwesomeIcon
                    icon={faChartBar}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setReportModalShow(true)}
                /> */}
            </div>
            <TransitFilterModal
                show={filterModalShow}
                onHide={() => setFilterModalShow(false)}
                onApply={applyFilters}
                zones={zones}
            />
            <Row>
                {/* Table Header */}
                <Col className="mt-1 mb-1" xs={2} lg={2}>Zone</Col>
                <Col className="mt-1 mb-1" xs={2} lg={2}>VLN</Col>
                <Col className="mt-1 mb-1" xs={3} lg={3}>Entry</Col>
                <Col className="mt-1 mb-1" xs={3} lg={3}>Exit</Col>
                <Col className="mt-1 mb-1" xs={1} lg={1}>Status</Col>
                <Col className="mt-1 mb-1" xs={1} lg={1}></Col>
            </Row>
            {loading ? (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : transits && transits.length > 0 && transits.map((transit, index) => (
                <div key={index} style={{ backgroundColor: index % 2 === 0 ? '#101014' : '#2d2d39' }} className="transit">
                    <Row key={index}>
                        <Col className="mt-1 mb-1" xs={2} lg={2}>
                            {transit.zone_name}
                        </Col>
                        <Col className="mt-1 mb-1" xs={2} lg={2}>
                            {transit.entry_device_event ? transit.entry_device_event.vln : transit.exit_device_event.vln}
                        </Col>
                        <Col className="mt-1 mb-1" xs={3} lg={3}>
                            {transit.entry_device_event ? transit.entry_device_event.event_time : '---'}                      
                        </Col>
                        <Col className="mt-1 mb-1" xs={3} lg={3}>
                            {transit.exit_device_event ? transit.exit_device_event.event_time : '---'}
                        </Col>
                        <Col className="mt-1 mb-1" xs={1} lg={1}>
                            {transit.status}
                        </Col>
                        <Col className="mt-1 mb-1" xs={1} lg={1}>
                            {/* <TransitModal transit={transit}/> */}
                            <TransitModal zone={null} backendAPI={backendAPI} transit={transit}/>
                            {/* <TransitModal device={null} backendAPI={backendAPI} websocket={websocket} liveDeviceEventUpdate={false} device_event={device_event}/> */}
                        </Col>
                        {/* <Col>
                            <TransitModal transit={transit} />
                        </Col> */}
                    </Row>
                </div>
            ))}
            {/* Pagination Controls */}
            <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
            <button onClick={() => setCurrentPage(currentPage + 1)} disabled={(currentPage * pageSize) >= transits ? transits.length : 0}>Next</button>
        </div>
    );
}
