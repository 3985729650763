// ZonesTable.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import TransitModal from './TransitModal';
import { ZoneSettingsModal } from './ZoneSettingsModal'; // Assuming this is similar to DeviceSettingsModal
import { ConfirmationDialog } from './common/ConfirmationDialog'; // Reuse for delete confirmation

export function ZonesTable({ backendAPI, zones, zoneChangedCallback, devices }) {
    // const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [showTransitModal, setShowTransitModal] = useState(false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

    useEffect(() => {
    }, [zones]);

    async function handleEditZone(zone) {
        try {
            const response = await backendAPI.updateZone(zone);
            if (response.status === 200) {
                zoneChangedCallback();
            }
            console.log("Update is complete");
            return response;
        } catch (error) {
            console.error('Error editing zone:', error);
            return null;
        }
    }

    async function handleAddZone(zone) {
        try {
            const response = await backendAPI.createZone(zone);
            if (response.status === 201) {
                zoneChangedCallback();
            }
            console.log("Create is complete");
            return response;
        } catch (error) {
            console.error('Error adding zone:', error);
            return null;
        }
    }

    async function handleDeleteZone(zoneId) {
        setIsConfirmDeleteOpen(false);
        try {
            const response = await backendAPI.deleteZone(zoneId);
            if (response.status === 200) {
                zoneChangedCallback();
            }
        } catch (error) {
            console.error('Error deleting zone:', error);
        }
    }

    const handleShowConfirmDelete = (zone) => {
        setSelectedZone(zone);
        setIsConfirmDeleteOpen(true);
    };

    return (
        <div>
            <Row className="zone-header">
                <Col className="mt-1 mb-1" xs={8} lg={10}><strong>Zone Name</strong></Col>
                <Col className="mt-1 mb-1" xs={2} lg={1}><strong>Actions</strong></Col>
                <Col className="mt-1 mb-1" xs={2} lg={1}>
                    <ZoneSettingsModal 
                        zone={null} 
                        submit_callback={handleAddZone} 
                        icon={faPlus} 
                        title="Add Zone" 
                        backendAPI={backendAPI}
                    />
                </Col>
            </Row>
            {zones.map((zone) => (
                <Row key={zone.id} className="zone-row">
                    <Col className="mt-1 mb-1" xs={10} lg={11}>{zone.name}</Col>
                    <Col className="mt-1 mb-1" xs={2} lg={1}>
                        <Row>
                            <Col className="mt-1 mb-1" xs={3} lg={3}>
                                <ZoneSettingsModal
                                    zone={zone}
                                    submit_callback={handleEditZone}
                                    icon={faEdit}
                                    title="Edit Zone"
                                    backendAPI={backendAPI}
                                />
                            </Col>
                            <Col className="mt-1 mb-1" xs={3} lg={3}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    style={{ color: "red", cursor: "pointer", marginLeft: '10px' }}
                                    onClick={() => handleShowConfirmDelete(zone)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ))}
            {selectedZone && (
                <ConfirmationDialog
                    title="Delete Zone?"
                    body={`Are you sure you want to delete the zone: ${selectedZone.name}?`}
                    show={isConfirmDeleteOpen}
                    onHide={() => setIsConfirmDeleteOpen(false)}
                    onConfirm={() => handleDeleteZone(selectedZone.id)}
                />
            )}
        </div>
    );
}

export default ZonesTable;
