// TransitReport.js
import React, { useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

export function TransitReport({ backendAPI, zones }) {
    const [selectedZones, setSelectedZones] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState('');

    const fetchReport = () => {
        // Call backendAPI.getTransitReport with selected zones, startDate, endDate, and status
    };

    return (
        <Form>
            <Form.Group>
                <Form.Label>Zones</Form.Label>
                <Form.Control as="select" multiple value={selectedZones} onChange={(e) => setSelectedZones([...e.target.selectedOptions].map(o => o.value))}>
                    {zones.map((zone, index) => (
                        <option key={index} value={zone.id}>{zone.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
            </Form.Group>
            <Form.Group>
                <Form.Label>End Date</Form.Label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
            </Form.Group>
            <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">Select Status</option>
                    <option value="OPEN">Open</option>
                    <option value="CLOSED">Closed</option>
                    <option value="EXPIRED">Expired</option>
                </Form.Control>
            </Form.Group>
            <Button onClick={fetchReport}>Generate Report</Button>
        </Form>
    );
}
