import { Container, Row, Col, Navbar, Nav, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import { Link } from 'react-router-dom';

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChartBar } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/generic_logo.png'

import { DeviceTable } from './DeviceTable.js';
import { DeviceEventTable } from './DeviceEventTable.js';
import { DeviceEventFilterModal } from './DeviceEventFilterModal.js';
import { DeviceEventReport } from './DeviceEventReport.js';
import { ManageUsersModal } from './ManageUsersModal.js';

function Dashboard(props) {
    const backendAPI = props.backendAPI;
    const websocket = props.websocket;

    const [user, setUser] = useState(null);

    const [filterModalShow, setFilterModalShow] = useState(false);
    const [reportModalShow, setReportModalShow] = useState(false);
    const [manageUsersModalShow, setManageUsersModalShow] = useState(false);
    const [filters, setFilters] = useState({});
    const [devices, setDevices] = useState([]);
    const [isLoadingDevices, setIsLoadingDevices] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        checkAdminStatus();
        fetchDevices();
    }, []);

    async function fetchDevices() {
        setIsLoadingDevices(true); // Set loading true before fetching
        try {
            const response = await backendAPI.getDevices();
            if (response && response.data) {
                setDevices(response.data.devices); // Store devices in state
            }
        } catch (error) {
            console.error('Error fetching devices:', error);
        } finally {
            setIsLoadingDevices(false); // Set loading false after fetching
        }
    }

    function checkAdminStatus() {
        const token = localStorage.getItem('authToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            if (decodedToken.is_admin && !isAdmin) {
                setIsAdmin(true);
            }
        }
    }

    function logout() {
        localStorage.removeItem('authToken');
    }

    const applyFilters = (filterParams) => {
        setFilters(filterParams);
    };

    return (
        <Container fluid style={{height: '100vh'}} className="background-light-grey text-default-color">
            <Row>
                <Navbar collapseOnSelect expand={false} bg="light" variant="light" fixed="top">
                    <Container className="d-flex justify-content-center">
                        <img
                            src={logo}
                            height="50"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                        <div className="d-flex justify-content-center flex-grow-1">
                            <Navbar.Brand href="/">TRACER</Navbar.Brand>
                        </div>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ml-auto"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                            <Nav.Link as={Link} to="/transits">Transits</Nav.Link>
                            <Nav.Link href="/login" onClick={logout}>Logout</Nav.Link>
                            {isAdmin && (<Nav.Link onClick={() => setManageUsersModalShow(true)}>Manage Users</Nav.Link>)}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Row>

            <div style={{height:"50px"}}/>
            <Row style={{paddingLeft: 0, paddingRight: 0}} className="justify-content-md-center">
                <Col className="mt-1 mb-1" xs={12} lg={10} >
                    <hr/>
                    <center className="text-headings text-large">DEVICES</center>
                    <DeviceTable 
                        backendAPI={backendAPI}
                        websocket={websocket}
                        devices={devices}
                        isLoadingDevices={isLoadingDevices}
                        refreshDevices={fetchDevices}
                    />
                    <hr/>
                    <center className="text-headings text-large">DEVICE EVENTS</center>
                    <div style={{ textAlign: "right" }}>
                        <FontAwesomeIcon
                            icon={faFilter}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setFilterModalShow(true)}
                        />
                        <FontAwesomeIcon
                            icon={faChartBar}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setReportModalShow(true)}
                        />
                    </div>
                    <DeviceEventTable 
                        backendAPI={backendAPI}
                        websocket={websocket}
                        filters={filters}
                    />
                </Col>
            </Row>

            <Row className="fs-3">
                
            </Row>

            <DeviceEventFilterModal
                show={filterModalShow}
                onHide={() => setFilterModalShow(false)}
                onApply={applyFilters}
                devices={devices}
            />

            <Modal show={reportModalShow} onHide={() => setReportModalShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Device Event Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeviceEventReport backendAPI={backendAPI} devices={devices} /> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setReportModalShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <ManageUsersModal
                show={manageUsersModalShow}
                onHide={() => setManageUsersModalShow(false)}
                backendAPI={backendAPI}
            />

        </Container>
    );
}

export default Dashboard;
    