import React, { useState } from 'react';
import { Form, Button, Row, Col, Table, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format, addHours, addDays, addWeeks, addMonths, addYears, differenceInHours, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears } from 'date-fns'; 

export function DeviceEventReport({ backendAPI, devices }) {
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [bucketSize, setBucketSize] = useState('hourly');
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);

    const bucketOptions = ['hourly', 'daily', 'weekly', 'monthly', 'yearly'];

    const handleDeviceSelection = (deviceId) => {
        setSelectedDevices((prevSelected) =>
            prevSelected.includes(deviceId)
                ? prevSelected.filter((id) => id !== deviceId)
                : [...prevSelected, deviceId]
        );
    };

    // Helper function to generate time buckets between start and end date
    const generateTimeBuckets = (start, end, bucketSize) => {
        let currentDate = new Date(start);
        const endDate = new Date(end);
        const now = new Date();
        const timeBuckets = [];

        console.log('Generating time buckets from', currentDate, 'to', endDate, 'with bucket size', bucketSize);
        console.log('Current Date:', now);

        while (currentDate <= endDate && currentDate <= now) {  // Limit to current date/time
            timeBuckets.push(new Date(currentDate));
            console.log('Adding:', currentDate);
            switch (bucketSize) {
                case 'hourly':
                    currentDate = addHours(currentDate, 1);
                    break;
                case 'daily':
                    currentDate = addDays(currentDate, 1);
                    break;
                case 'weekly':
                    currentDate = addWeeks(currentDate, 1);
                    break;
                case 'monthly':
                    currentDate = addMonths(currentDate, 1);
                    break;
                case 'yearly':
                    currentDate = addYears(currentDate, 1);
                    break;
                default:
                    break;
            }
            
            if(currentDate > now) {
                console.log("stopping generation because curent > now", currentDate, now);
            }
        }
        return timeBuckets;
    };

    const fetchReport = async () => {
        if (!selectedDevices.length || !startDate || !endDate) {
            alert('Please select devices, a start date, and an end date');
            return;
        }

        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        endDate.setMinutes(0);
        endDate.setSeconds(0);
        endDate.setMilliseconds(0);

        if(bucketSize === 'daily') {
            startDate.setHours(0);
            endDate.setHours(0);
        } if(bucketSize === 'weekly') {
            // Set the startDate to the start of the week (Monday)
            const startDay = startDate.getDay();
            const daysToSubtract = (startDay === 0) ? 6 : startDay - 1;  // Adjust for Sunday (0) being treated as the last day of the week
            startDate.setDate(startDate.getDate() - daysToSubtract);
            startDate.setHours(0, 0, 0, 0);  // Set to 00:00 of the day

            // Set the endDate to the start of the week (Monday)
            const endDay = endDate.getDay();
            const daysToSubtractEnd = (endDay === 0) ? 6 : endDay - 1;  // Adjust for Sunday
            endDate.setDate(endDate.getDate() - daysToSubtractEnd);
            endDate.setHours(0, 0, 0, 0); 
        } else if(bucketSize === 'monthly') {
            startDate.setHours(0);
            startDate.setDate(1);
            endDate.setHours(0);
            endDate.setDate(1);
            endDate.setMonth(endDate.getMonth() + 1);
        } else if(bucketSize === 'yearly') {
            startDate.setHours(0);
            startDate.setMonth(0);
            startDate.setDate(1);
            endDate.setHours(0);
            endDate.setMonth(0);
            endDate.setDate(1);
            endDate.setYear(endDate.getYear() + 1);
        }

        setLoading(true);
        console.log('Fetching report with start and end date:', startDate, endDate);
        const response = await backendAPI.getDeviceEventReport({
            devices: selectedDevices,
            startDate,
            endDate,
            bucketSize,
        });
        setLoading(false);

        if (response && response.data) {
            const filledReportData = fillMissingBuckets(response.data.event_counts);
            setReportData(filledReportData);
        }
    };

    // Fill in missing time buckets with 0 counts for each device
    const fillMissingBuckets = (eventCounts) => {
        const generatedBuckets = generateTimeBuckets(startDate, endDate, bucketSize);
        const filledData = generatedBuckets.map((bucket) => {
            const formattedBucket = formatTime(bucket);
            const existingBucket = eventCounts.find(event => formatTime(event.time) === formattedBucket);
            if (existingBucket) {
                return existingBucket; // Use existing data from backend
            } else {
                // Create a new bucket with 0 counts for each device
                return {
                    time: bucket,
                    device_event_counts: selectedDevices.map(deviceId => ({
                        device: deviceId,
                        event_count: 0
                    }))
                };
            }
        });
        return filledData;
    };

    // Helper function to format the time based on bucket size
    const formatTime = (time) => {
        const date = new Date(time);
        switch (bucketSize) {
            case 'hourly':
                return format(date, 'yyyy-MM-dd HH:mm'); // Date and Time for hourly
            case 'daily':
                return format(date, 'yyyy-MM-dd'); // Only Date for daily
            case 'weekly':
                return format(date, 'yyyy-MM-dd'); // Start of the week for weekly
            case 'monthly':
                return format(date, 'MMMM yyyy'); // Month name and Year for monthly
            case 'yearly':
                return format(date, 'yyyy'); // Only Year for yearly
            default:
                return time;
        }
    };

    const getReportGenerationTime = () => format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    // Display the selected time bucket and date range
    const reportTimeBucket = `${bucketSize.charAt(0).toUpperCase() + bucketSize.slice(1)}`;
    const reportDateRange = `${startDate ? format(startDate, 'yyyy-MM-dd') : ''} to ${endDate ? format(endDate, 'yyyy-MM-dd') : ''}`;
    const reportGeneratedAt = `${getReportGenerationTime()}`;

    // Export Table Data to Excel
    const exportToExcel = () => {
        // Worksheet data with report criteria and report generation time in separate rows
        const worksheetData = [
            // Each array inside is treated as a row in Excel
            [{ v: 'Time Bucket: ' + reportTimeBucket }],
            [{ v: 'Date Range: ' + reportDateRange }],
            [{ v: 'Generated at: ' + reportGeneratedAt }],
            [], // Empty row for spacing

            // Column headers
            ['Time', ...devices.filter((device) => selectedDevices.includes(device.id)).map((device) => device.location)],

            // Data rows
            ...reportData.map((bucket) => {
                const row = [
                    formatTime(bucket.time), // Time bucket
                    ...devices
                        .filter((device) => selectedDevices.includes(device.id))
                        .map((device) => {
                            const deviceCount = bucket.device_event_counts.find((event) => event.device === device.id);
                            return deviceCount ? deviceCount.event_count : 0; // Event count per device
                        })
                ];
                return row;
            })
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);  // Use aoa_to_sheet to treat each array as a row
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
        XLSX.writeFile(workbook, 'device_event_report.xlsx');
    };


    // Export Table Data to PDF
    const exportToPDF = () => {
        const doc = new jsPDF();

        const tableColumn = ['Time'];
        selectedDevices.forEach((deviceId) => {
            const device = devices.find((d) => d.id === deviceId);
            tableColumn.push(device ? device.location : '');
        });

        const tableRows = reportData.map((bucket) => {
            const row = [formatTime(bucket.time)];
            devices
                .filter((device) => selectedDevices.includes(device.id))
                .forEach((device) => {
                    const deviceCount = bucket.device_event_counts.find((event) => event.device === device.id);
                    row.push(deviceCount ? deviceCount.event_count : 0);
                });
            return row;
        });

        doc.text('Device Event Report', 14, 15);
        doc.autoTable({
            head: [['Time Bucket: ' + reportTimeBucket]],
            theme: 'plain',
            styles: { fontSize: 10 },
            startY: 18,
            margin: { left: 14 }
        });
        doc.autoTable({
            head: [['Date Range: ' + reportDateRange]],
            theme: 'plain',
            styles: { fontSize: 10 },
            startY: 23,
            margin: { left: 14 }
        });
        doc.autoTable({
            head: [['Generated at: ' + reportGeneratedAt]],
            theme: 'plain',
            styles: { fontSize: 10 },
            startY: 28,
            margin: { left: 14 }
        });
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 42,
        });

        doc.save('device_event_report.pdf');
    };

    return (
        <div>
            <h2>Device Event Report</h2>

            <Form>
                {/* Device Selection */}
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Select Devices</Form.Label>
                    <Col sm={10}>
                        <Dropdown>
                            <Dropdown.Toggle>Select Devices</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {devices.map((device) => (
                                    <Dropdown.Item key={device.id} as="div">
                                        <Form.Check
                                            type="checkbox"
                                            label={device.location}
                                            value={device.id}
                                            checked={selectedDevices.includes(device.id)}
                                            onChange={() => handleDeviceSelection(device.id)}
                                        />
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Form.Group>
                
                <hr className="my-3" />

                {/* Bucket Size Selection */}
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Bucket Size</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            value={bucketSize}
                            onChange={(e) => setBucketSize(e.target.value)}
                        >
                            {bucketOptions.map((bucket, index) => (
                                <option key={index} value={bucket}>
                                    {bucket.charAt(0).toUpperCase() + bucket.slice(1)}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Form.Group>
                
                <hr className="my-3" />

                {/* Time Range Selection */}
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Start Date</Form.Label>
                    <Col sm={10}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        
                        // Show time picker for hourly, otherwise handle based on bucket size
                        showTimeSelect={bucketSize === 'hourly'}
                        
                        // Conditional display format based on bucket size
                        dateFormat={
                            bucketSize === 'hourly'
                                ? "Pp"
                                : bucketSize === 'daily'
                                ? "yyyy-MM-dd"
                                : bucketSize === 'weekly'
                                ? "yyyy-MM-dd"  // Weeks handled separately
                                : bucketSize === 'monthly'
                                ? "MMMM yyyy"   // Month and year for monthly
                                : bucketSize === 'yearly'
                                ? "yyyy"        // Year only for yearly
                                : "Pp"
                        }
                        
                        // Custom views for month and year selection
                        showMonthYearPicker={bucketSize === 'monthly'}  // Show month-year picker for monthly bucket
                        showYearPicker={bucketSize === 'yearly'}        // Show year picker for yearly bucket
                        placeholderText="Select Start Date"
                    />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm={2}>End Date</Form.Label>
                    <Col sm={10}>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        
                        // Show time picker for hourly, otherwise handle based on bucket size
                        showTimeSelect={bucketSize === 'hourly'}
                        
                        // Conditional display format based on bucket size
                        dateFormat={
                            bucketSize === 'hourly'
                                ? "Pp"
                                : bucketSize === 'daily'
                                ? "yyyy-MM-dd"
                                : bucketSize === 'weekly'
                                ? "yyyy-MM-dd"  // Weeks handled separately
                                : bucketSize === 'monthly'
                                ? "MMMM yyyy"   // Month and year for monthly
                                : bucketSize === 'yearly'
                                ? "yyyy"        // Year only for yearly
                                : "Pp"
                        }
                        
                        // Custom views for month and year selection
                        showMonthYearPicker={bucketSize === 'monthly'}  // Show month-year picker for monthly bucket
                        showYearPicker={bucketSize === 'yearly'}        // Show year picker for yearly bucket
                        placeholderText="Select End Date"
                    />
                    </Col>
                </Form.Group>
                
                <hr className="my-3" />

                <center>
                <Button onClick={fetchReport} disabled={loading}>
                    {loading ? 'Loading...' : 'Fetch Report'}
                </Button>

                <Button onClick={exportToExcel} className="ms-2">Export to Excel</Button>
                <Button onClick={exportToPDF} className="ms-2">Export to PDF</Button>
                </center>
            </Form>

            {reportData.length > 0 && <hr className="my-3" />}

            {/* Report Criteria Display */}
            {reportData.length > 0 && <p><strong>Time Bucket:</strong> {reportTimeBucket}</p>}
            {reportData.length > 0 && <p><strong>Date Range:</strong> {reportDateRange}</p>}
            {reportData.length > 0 && <p><strong>Generated at:</strong> {reportGeneratedAt}</p>}

            {/* Report Data Table */}
            {reportData.length > 0 && (
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>Time Bucket</th>
                            {devices
                                .filter(device => selectedDevices.includes(device.id))
                                .map(device => (
                                    <th key={device.id}>{device.location}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {reportData.map((bucket, index) => (
                            <tr key={index}>
                                <td>{formatTime(bucket.time)}</td>
                                {devices
                                    .filter(device => selectedDevices.includes(device.id))
                                    .map(device => {
                                        const deviceCount = bucket.device_event_counts.find(
                                            (event) => event.device === device.id
                                        );
                                        return (
                                            <td key={device.id}>{deviceCount ? deviceCount.event_count : 0}</td>
                                        );
                                    })}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
}
