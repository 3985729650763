import { useState } from 'react';
import { Modal, Button, Form, Check } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

export function DeviceSettingsModal(props) {
	const device = props.device;
    const submit_callback = props.submit_callback;
    const icon = props.icon;
    const title = props.title;

    const [showModal, setShowModal] = useState(false);

	const [MACAddress, setMACAddress] = useState(device ? device.mac_address : '');
	const [serialNumber, setSerialNumber] = useState(device ? device.serial_number : '');
	const [description, setDescription] = useState(device ? device.description: '');
	const [location, setLocation] = useState(device ? device.location: '');
	const [saveANPRImages, setSaveANPRImages] = useState(device ? device.save_anpr_images : false);
	const [saveOverviewImages, setSaveOverviewImages] = useState(device ? device.save_overview_images : false);

	function openModal() {
		setShowModal(true);
		console.log(device);
	};

	function closeModal() {
		setMACAddress(device ? device.mac_address : '');
		setSerialNumber(device ? device.serial_number : '');
		setDescription(device ? device.location: '');
		setLocation(device ? device.description: '');
		setSaveANPRImages(device ? device.save_anpr_images : false);
		setSaveOverviewImages(device ? device.save_overview_images : false);
		setShowModal(false);
	};

	function isValidMACAddress(mac) {
		const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
		return regex.test(mac);
	}	

	async function handleModalSubmit() {
		try {
			if(!isValidMACAddress(MACAddress)) {
				document.getElementById('modalError').innerHTML = "Invalid MAC Address";
				document.getElementById('modalError').style.display = "block"
				document.getElementById('modalError').style.color = '#e24247';
			} else {
				let dev = {}
				if(device)
					dev.id = device.id
				dev.mac_address = MACAddress;
				dev.serial_number = serialNumber;
				dev.description = description;
				dev.location = location;
				dev.save_anpr_images = saveANPRImages;
				dev.save_overview_images = saveOverviewImages;
                const response = await submit_callback(dev)
				if(response && (response.status === 200 || response.status === 201) ) {
					document.getElementById('modalError').innerHTML = "Success";
					document.getElementById('modalError').style.display = "block"
					document.getElementById('modalError').style.color = '#42e2dd';
					setTimeout(closeModal, 1000);
				} else if (response) {
					document.getElementById('modalError').innerHTML = response.data.message;
					document.getElementById('modalError').style.display = "block"
					document.getElementById('modalError').style.color = '#e24247';
				} else {
					document.getElementById('modalError').innerHTML = "Something went wrong";
					document.getElementById('modalError').style.display = "block"
					document.getElementById('modalError').style.color = '#e24247';
				}
			}
		} catch (error) {
			console.error('Error executing device update:', error);
		}
	}

	return (
		<div>
			<FontAwesomeIcon icon={icon} style={{ cursor: "pointer" }} onClick={() => openModal()}/>
			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>MAC Address</Form.Label>
							<Form.Control
								type="text"
								value={MACAddress}
								onChange={(e) => setMACAddress(e.target.value)}
								placeholder="Enter MAC Address"
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Serial Number</Form.Label>
							<Form.Control
								type="text"
								value={serialNumber}
								onChange={(e) => setSerialNumber(e.target.value)}
								placeholder="Enter Serial Number"
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Location</Form.Label>
							<Form.Control
								type="text"
								value={location}
								onChange={(e) => setLocation(e.target.value)}
								placeholder="Enter Location"
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Description</Form.Label>
							<Form.Control
								type="text"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								placeholder="Enter Description"
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Check 
								type="checkbox" 
								label="Save ANPR Images" 
								checked={saveANPRImages}
								onChange={(e) => setSaveANPRImages(e.target.checked)}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Check 
								type="checkbox" 
								label="Save Overview Images" 
								checked={saveOverviewImages}
								onChange={(e) => setSaveOverviewImages(e.target.checked)}
							/>
						</Form.Group>
					</Form>
					<center><label id="modalError"></label></center>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal}>
						Cancel
					</Button>
					<Button variant="primary" onClick={handleModalSubmit}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}
