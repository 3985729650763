// Transits.js
import { Container, Row, Col, Navbar, Nav, Modal, Button, Spinner } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilter, faChartBar } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/generic_logo.png'


import { ZonesTable } from './ZonesTable';
import { TransitTable } from './TransitTable.js';
// import { TransitFilterModal } from './TransitFilterModal.js';
import { TransitReport } from './TransitReport.js';

export function Transits({ backendAPI, websocket }) {
    const [reportModalShow, setReportModalShow] = useState(false);
    const [zones, setZones] = useState([]);

    useEffect(() => {
        fetchZones();
    }, []);

    async function fetchZones() {
        try {
            const response = await backendAPI.getZones();
            if (response.data && response.data.zones) {
                setZones(response.data.zones);
                // fetchZonesCallback(response.data.zones);
            }
        } catch (error) {
            console.error('Error fetching zones:', error);
        }
    }

    const logout = () => {
        localStorage.removeItem('authToken');
    };

    return (
        <Container fluid style={{height: '100vh'}} className="background-light-grey text-default-color">
            <Row>
                <Navbar collapseOnSelect expand={false} bg="light" variant="light" fixed="top">
                    <Container className="d-flex justify-content-center">
                        <img
                            src={logo}
                            height="50"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                        <div className="d-flex justify-content-center flex-grow-1">
                            <Navbar.Brand href="/">TRACER</Navbar.Brand>
                        </div>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ml-auto"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                            <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
                            <Nav.Link href="/login" onClick={logout}>Logout</Nav.Link>
                            {/* {isAdmin && (<Nav.Link onClick={() => setManageUsersModalShow(true)}>Manage Users</Nav.Link>)} */}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Row>

            <div style={{height:"50px"}}/>
            <Row style={{paddingLeft: 0, paddingRight: 0}} className="justify-content-md-center">
                <Col className="mt-1 mb-1" xs={12} lg={10} >
                    <hr/>
                    <center className="text-headings text-large">ZONES</center>
                    <ZonesTable backendAPI={backendAPI} zones={zones} zoneChangedCallback={fetchZones}/>
                    <hr/>
                    <center className="text-headings text-large">TRANSITS</center>
                    <TransitTable
                        backendAPI={backendAPI}
                        websocket={websocket}
                        zones={zones}
                    />
                </Col>
            </Row>

            <Row className="fs-3">
                
            </Row>

            {/* <TransitFilterModal
                show={filterModalShow}
                onHide={() => setFilterModalShow(false)}
                onApply={applyFilters}
                zones={zones}
            /> */}

            <Modal show={reportModalShow} onHide={() => setReportModalShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Transit Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TransitReport backendAPI={backendAPI} zones={zones} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setReportModalShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <ManageUsersModal
                show={manageUsersModalShow}
                onHide={() => setManageUsersModalShow(false)}
                backendAPI={backendAPI}
            /> */}

        </Container>
    );
}

export default Transits;
