import io from 'socket.io-client';

export class Websocket {
    constructor(server_url) {
        this.socket = io(server_url);
        this.deviceEventCallbacks = [];

        this.socket.on('aitrac_event', (data) => {
            // console.log('Got websocket device_event:', data);
            this.deviceEventCallbacks.forEach(callback => {
                callback(data);
            });
        });
    }

    registerDeviceEventCallback(callback) {
        this.deviceEventCallbacks.push(callback);
    }

    deregisterDeviceEventCallback(callback) {
        this.deviceEventCallbacks = this.deviceEventCallbacks.filter(cb => cb !== callback);
    }

    disconnect() {
        this.socket.disconnect();
    }
};

export default Websocket;
